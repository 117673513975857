import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { authGuard, useAuth0 } from "@auth0/auth0-vue";
import { userStore } from "@/stores/UserStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/products/insurance",
    beforeEnter: [authGuard, checkRoleAuthGuard],
    // HomeView page will be brought back in later releases
    // component: () =>
    //   import("@/pages/HomeView.vue"),
  },
  {
    path: "/products/insurance",
    name: "insurance-product",
    beforeEnter: [authGuard, checkRoleAuthGuard],
    component: () => import("@/pages/products/insurance/InsuranceHome.vue"),
    children: [
      {
        path: "",
        name: "insurance-home-default",
        component: () =>
          import("@/pages/products/insurance/insurance-home-default/InsuranceHomeDefault.vue"),
      },
      {
        path: "quote-and-apply",
        name: "insurance-quote-and-apply",
        component: () => import("@/pages/products/insurance/quote-and-apply/QuoteAndApply.vue"),
      },
      {
        path: "quote-and-apply/application",
        name: "insurance-application",
        component: () => import("@/pages/products/insurance/application/ApplicantDetailsCheck.vue"),
      },
      {
        path: "applications/:id",
        name: "applications-details",
        component: () =>
          import("@/pages/products/insurance/policies-and-application/ApplicationDetails.vue"),
      },
      {
        path: "policies/:id",
        name: "policies-details",
        component: () =>
          import("@/pages/products/insurance/policies-and-application/PolicyDetails.vue"),
      },
    ],
  },
  {
    path: "/clients",
    name: "clients",
    beforeEnter: [authGuard, checkRoleAuthGuard],
    component: () => import("@/pages/AdviserHubClients.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// Work around to fix the auth0 lib redirection loosing base url issue
router.beforeEach(() => {
  if (!window.location.href.includes(process.env.BASE_URL) && process.env.BASE_URL.length > 1) {
    window.location.href = window.location.origin + process.env.BASE_URL + "#/";
  }
});

function checkRoleAuthGuard() {
  const authService = useAuth0();
  const user = authService.user.value;
  if (!user || user["https://booster.co.nz/claims"] === undefined) {
    return Error("No valid user");
  }

  const bapRole = user["https://booster.co.nz/claims"].find(
    (x: any) => x.key == "BAP:ExternalReference"
  );

  if (!bapRole || !bapRole.value || bapRole.value.length <= 0) {
    throw Error("No valid role.");
  }

  const adviserListConfig: string = process.env.VUE_APP_ADV_LIST;
  const adviserList = adviserListConfig.split(",").map((x: string) => Number(x));
  const currentAdviserId = Number(bapRole.value);
  const userInfo = userStore();

  const adviserName = user["https://booster.co.nz/claims"].find(
    (x: any) => x.key == "display_name"
  ).value;

  userInfo.setAdviserId(currentAdviserId);
  userInfo.setUserName(adviserName);

  if (!adviserList.find((x) => x == currentAdviserId)) {
    return Error("User is not allowed to access.");
  }

  return true;
}

export default router;
