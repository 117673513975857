<template>
  <div class="booster-nav">
    <div class="nav-width col-12 row">
      <div class="col-2"></div>
      <div class="col-4 pt-2">
        <a href="https://www.booster.co.nz/" target="_blank">
          <img class="logo" alt="Booster" src="@/assets/logo.svg" />
        </a>
      </div>
      <div class="col-4 row justify-content-end pt-1">
        <div class="col-auto px-2 p-fluid grid d-none">
          <div class="field md:col-5">
            <span class="p-input-icon-right">
              <i class="pi pi-search" />
              <InputText
                v-model="searchVal"
                type="text"
                placeholder="Client search..."
                class="rounded" />
            </span>
          </div>
        </div>
        <div class="col-auto ps-1 pe-0 d-none">
          <Dropdown
            v-model="adviser"
            :options="[]"
            placeholder="&#x1F441; Advisers company"
            class="rounded" />
        </div>
        <div v-if="isAuthenticated" class="col-auto px-0">
          <div class="dropdown">
            <button
              id="dropdownMenuButton1"
              class="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <img class="user-icon" src="@/assets/user.svg" />
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item d-none" href="#">Change password</a></li>
              <li><a class="dropdown-item" @click="handleLogout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
    name: "BoosterHeader",
    components: {
        InputText,
        Dropdown
    },
    data() {
        return {
            searchVal: '',
            adviser: '',
            isDropdownExpanded: false,
            isAuthenticated: this.$auth0.isAuthenticated
        }
    },
    methods: {
        handleLogout() {
            this.$auth0.logout({
                logoutParams: {
                    returnTo: window.location.origin + process.env.BASE_URL,
                },
            });
        }
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/booster-styles.scss";

.booster-nav {
    background-color: $booster-blue;
    height: 3.125rem;

    .logo {
        height: 2.063rem;
    }
}

.nav-width {
    width: 100%;
}

.p-input-icon-right i:last-of-type {
    color: $booster-blue;
}

::placeholder {
    color: $booster-blue;
}

.p-dropdown {
    background-color: #125386;
    border-color: #125386;
}

#dropdownMenuButton1.btn {
    border-color: $booster-blue;
}

:deep(.p-dropdown-label) {
    color: white !important;
}

:deep(.pi-chevron-down:before) {
    color: white !important;
    font-size: 0.75rem;
}

.user-icon {
    height: 1.125rem;
    width: 1.125rem;
}
</style>
