<template>
  <hr />
  <div class="px-5">
    <div class="row">
      <div class="col-10 text-start">
        <div class="item-body-small">
          <a
            href="https://www.booster.co.nz/products-services/insurance/booster-smartcover"
            target="_blank">
            <i class="bi bi-globe2 pe-1"></i> booster.co.nz
          </a>
        </div>
        <div class="item-body-small">
          <a href="mailto:adviserservices@booster.co.nz" target="_blank">
            <i class="bi bi-envelope pe-1"></i> {{ email }}@booster.co.nz
          </a>
        </div>
        <div class="item-body-small"><i class="bi bi-telephone pe-1"></i> 0800 336 338</div>
        <div class="item-body-small">
          <i class="bi bi-envelope pe-1"></i> PO Box 11872, Manners Street, Wellington
        </div>
      </div>
      <div class="col-2 text-end">
        <a href="https://www.booster.co.nz/" target="_blank">
          <img class="logo" alt="Booster" src="@/assets/booster-blue.svg" />
        </a>
        <div class="d-flex pt-4 align-items-center justify-content-end">
          <a href="https://www.facebook.com/boosternz/" target="_blank">
            <img class="logo-icon me-1" src="@/assets/sm-facebook.png" />
          </a>
          <a href="https://www.linkedin.com/company/boosternz/" target="_blank">
            <img class="logo-icon me-1" src="@/assets/sm-linkedin.png" />
          </a>
          <a href="https://www.youtube.com/@BoosterNz" target="_blank">
            <img class="logo-icon" src="@/assets/sm-youtube.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "BoosterFooter",
    data() {
        return {
            email: 'adviserservices'
        }
    },
    // TODO will be used in future for other screens
    // watch: {
    //   $route(to) {
    //     this.email = to.path?.includes('insurance') ? 'insurance': 'adviserservices';
    //   }
    // }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.item-body-small {
    line-height: 1.25rem;
}

i {
    font-size: 0.625rem;
}

.logo-icon {
    width: 1.25rem;
    height: 1.25rem;
}
</style>
